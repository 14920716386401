import React from 'react'
import loadable from '@loadable/component'
import { graphql } from 'gatsby'
import { Container, Row, Col } from "react-bootstrap"
import Layout from '../components/layout'
import Seo from "../components/seo"

import GoogleReviews from '../components/GoogleReviews/GoogleReviews'

const Banner = loadable(() => import("../components/Banner/Banner"))
const WhyChoose = loadable(() => import("../components/WhyChoose/WhyChoose"))
const TileBlock = loadable(() => import("../components/TileBlock/TileBlock"))
const WhatDoYouNeed = loadable(() => import("../components/WhatDoYouNeed/WhatDoYouNeed"))
const CareerLanding = loadable(() => import("../components/CareerLanding/CareerLanding"))
const TestimonialSlider = loadable(() => import("../components/TestimonialSlider/TestimonialSlider"))
const SellLetHome = loadable(() => import("../components/SellLetHome/SellLetHome"))

const Careers = ({ data }) => {
    const PageData = data?.strapiPage
    const Jobs = data?.allStrapiCareer?.edges

    return (
        <Layout classname={PageData?.custom_css_classname} popularSearch={PageData?.select_popular_search?.title}>
            <Banner
                showBreadCrumb
                breadcrumbData={PageData.choose_menu[0]}
                pagename={PageData.title}
                {...PageData.banner}
                title={PageData.banner?.banner_title}
                id={PageData.strapi_id}
                imagetransforms={PageData.imagetransforms}
                btnPrimaryIconClass="icon-banner-valuation"
                btnPrimaryLightIconClass="icon-valuation-home"
            />

            {PageData?.add_page_modules?.length > 0 && PageData.add_page_modules?.map((module, index) => {
                return (
                    <>
                        {module.strapi_component === "page-modules.global-module" && module.select_module === "google_review_badge" && <GoogleReviews />}
                        {module.strapi_component === "page-modules.services-tiles" && <WhatDoYouNeed id={PageData.strapi_id} {...module} />}
                        {module.strapi_component === "page-modules.image-and-content" && module.image_alignment === "left" ? <TileBlock id={PageData.strapi_id} {...module} /> : module.strapi_component === "page-modules.image-and-content" && <><WhyChoose id={PageData.strapi_id} {...module} showDividerLine /><Container>
                            <Row>
                                <Col>
                                    <div className="border-line"></div>
                                </Col>
                            </Row>
                        </Container></>}
                        {module.strapi_component === "page-modules.plain-content" && module.layout === "bg_color_center_align" && <SellLetHome {...module} />}
                        {module.strapi_component === "page-modules.global-module" && module.select_module === "career" && <CareerLanding jobs={Jobs} {...module} />}
                        {module.strapi_component === "page-modules.video-review" && <TestimonialSlider {...module} id={PageData.strapi_id} />}
                    </>
                )
            }
            )}
        </Layout>
    )
}

export const Head = ({ data }) => {
    const PageData = data?.strapiPage
    return (
        <Seo title={PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title} description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : PageData?.title} />
    )
}

export default Careers


export const query = graphql`
    query ($page_id: String) {
      strapiPage(id: {eq: $page_id}) {
          ...PageFragment
          add_page_modules {
                ... on STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE {
                    ...GlobalModuleFragment
                }
                ... on STRAPI__COMPONENT_PAGE_MODULES_IMAGE_AND_CONTENT {
                    ...ImageAndContentFragment
                }
                ... on STRAPI__COMPONENT_PAGE_MODULES_PLAIN_CONTENT {
                    ...PlainContentFragment
                }
                ... on STRAPI__COMPONENT_PAGE_MODULES_SERVICES_TILES {
                    ...ServicesTilesFragment
                }
                ... on STRAPI__COMPONENT_PAGE_MODULES_STATISTICS {
                    ...StatsFragment
                }
                ... on STRAPI__COMPONENT_PAGE_MODULES_VIDEO_REVIEW {
                    ...VideoReviewFragment
                }
            }
        }
        allStrapiCareer(filter: {publish: {eq: true}}) {
          edges {
            node {
              title
              slug
              location
              category
            }
          }
        }
    }
  `