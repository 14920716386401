import React, { useState } from "react"
import { Navbar, Container, Row, Col, Offcanvas } from "react-bootstrap"
import { Link } from "gatsby"
import HeaderLogo from "../HeaderLogo/HeaderLogo"
import HeaderMenu from "../HeaderMenu/HeaderMenu"
import BurgerMenu from "../BurgerMenu/BurgerMenu"
import BurgerMenuList from "../BurgerMenuList/BurgerMenuList"
import useHasScrolled from "../../hooks/useHasScrolled"
import './assets/styles/_index.scss'
import LogoImg from "../../images/logo.svg"
import { PageLinks } from "../../common/site/page-static-links";
import SearchOverlayTrigger from "../SearchOverlay/SearchOverlay"

const Header = (props) => {

    // Scroll
    const scrolled = useHasScrolled()
    // Scroll

    // Burger menu
    const [showBurger, setShowBurger] = useState(false);
    const handleCloseBurger = () => setShowBurger(false);
    const handleShowBurger = () => setShowBurger(true);
    // Burger menu

    // Modal
    const [showFilterModal, setShowFilterModal] = useState(false);

    const handleCloseFilterModal = () => setShowFilterModal(false);
    const handleShowFilterModal = () => setShowFilterModal(true);
    // Modal

    return (
        <>
            <header className={`header d-flex align-items-center ${scrolled ? "header-scrolled" : ""}`}>
                <Navbar bg="" expand="xl" fixed="top">
                    <Container>
                        <HeaderLogo />

                        <HeaderMenu handleShowFilterModal={handleShowFilterModal} />

                        <BurgerMenu handleShowBurger={handleShowBurger} handleShowFilterModal={handleShowFilterModal}/>
                    </Container>
                </Navbar>
            </header>
            <Offcanvas show={showBurger} onHide={handleCloseBurger} placement="end" className="burger-menu-wrapper-offcanvas">
                <Offcanvas.Header closeButton>
                    <div className="w-100">
                        <Row className="d-flex align-items-center">
                            <Col md={6} xs={9}>
                                <Link to="/">
                                    <img src={LogoImg} alt="" className="logo-img" />
                                </Link>
                            </Col>
                            <Col md={6} xs={3}>
                                <ul className="list-inline offcanvas-burger-menu d-flex align-items-center justify-content-end">
                                    <li className="list-inline-item d-md-block d-none">
                                        <Link to={`/${PageLinks.valuation}/`} className="header-cta-btn d-flex align-items-center"><i className="icon icon-header-cta-arrow"></i> <span>Get a valuation</span></Link>
                                    </li>
                                    <li className="list-inline-item d-md-block d-none">
                                        <a href="javascript:;" onClick={() => handleShowFilterModal()} className="header-search-btn d-flex align-items-center justify-content-center"><i className="icon icon-header-search"></i></a>
                                    </li>
                                    <li className="list-inline-item d-md-none">
                                        <a href="javascript:;" onClick={() => handleShowFilterModal()} className="menu-link-sm">
                                            <i className="icon icon-header-search"></i>
                                            <div className="header-text-sm">Search</div>
                                        </a>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </div>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <BurgerMenuList />
                </Offcanvas.Body>
            </Offcanvas>
            <SearchOverlayTrigger
                handleCloseFilterModal={handleCloseFilterModal}
                showFilterModal={showFilterModal}
            />
        </>
    )
}

export default Header