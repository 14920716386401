/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/how-to/querying-data/use-static-query/
 */

import * as React from "react"
import Header from './Header/Header'
import loadable from "@loadable/component"
import "../styles/main.scss";
const Footer = loadable(() => import("./Footer/Footer"))

const Layout = ({ children, popularSearch, classname }) => {

  return (
    <div className={classname ? classname : ''}>
      <Header />
      <main>{children}</main>
      <Footer popularSearch={popularSearch} />
    </div>
  )
}

export default Layout